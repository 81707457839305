.caption {
    font-size: 50px;
    font-family: 'Roboto', sans-serif;
    margin-bottom: 50px;
    display: flex;
    justify-content: center;
}

.news-body-container {
    margin-left: 50px;
    margin-right: 50px;
    margin-top: 30px;
    margin-bottom: 50px;
}

.news-unit-header {
    font-family: 'Roboto', sans-serif;
}

.news-unit-date {
    margin-bottom: 20px;
}

.news-unit-content {
    margin-bottom: 50px;
}

.load-news-button {
    background-color: #C9B8A8 !important;
    display: flex;
    justify-content: center;
    margin-left: 45%;
    margin-right: 45%;
}

.load-news-button:hover {
    background-color: #918579 !important;
}

.load-news-button:active {
    background-color: #eee5db !important;
}