.pages-body-container {
    display: flex;
    flex-direction: row;
    margin-top: 20px;
    margin-left: 50px;
    margin-bottom: 20px;
}

.list-container {
    width: 300px;
}

.redactor-page-container {
    width: 100%;
    padding-left: 50px;
    padding-right: 50px;
}

.pages-header-selector {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.pages-button-bottom{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
}

.pages-button-document-border{
    background-color:#C9B8A8;
    border-radius: 20px;
    padding: 5px;
    margin-bottom: 10px;
}

.bordered-file-div {
    border: 1px solid rgba(0, 0, 0, 0.2);; 
    padding: 20px;                                      
    text-align: center; 
    border-radius: 20px;
}