.roles-body-container {
    margin-top: 20px;
    margin-left: 50px;
    margin-bottom: 20px;
    margin-right: 50px;
}

.roles-buttons-container{
    display: flex;
    flex-direction: row;
    gap: 10px;
    
}

.roles-main-buttons-container{
    margin-bottom: 20px;
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}