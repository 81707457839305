.manager-body-container {
    margin-top: 20px;
    margin-left: 50px;
    margin-right: 50px;
    margin-bottom: 20px;
}

.manager-logout-button{
    display: flex;
    flex-direction: row;
    justify-content: end;
    margin-right: 50px;
    margin-top: 20px;
}

.manager-buttons-container{
    display: flex;
    flex-direction: row;
    gap: 10px;
    
}

.manager-main-buttons-container{
    margin-bottom: 20px;
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.manager-element-small{
    display: flex;
    justify-content: flex-start;
    gap: 10px;
    margin-bottom: 10px;
    align-items: center;
}

.manager-element-header-text{
    font-size: 20px;
}

.manager-element-status{
    border-radius: 20px;
    border: 1px solid;
    padding: 7px;
}

.manager-element-header{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.manager-message-header{
    display: flex;
    justify-content: flex-start;
    gap: 10px;
    font-size: 10px;
    margin-left: 20px;
    opacity: 0.5;
}

.manager-message-text{
    font-size: 12px;
    word-wrap: break-word;
}

.manager-message-border{
    border: 0px solid black;
    padding: 10px;
    border-radius: 20px 20px 20px 0px;
    background-color: #e7e0d9;
}

.manager-bottom-buttons{
    display: flex;
    justify-content: space-between;
    gap: 10px;
    margin-top: 10px;
}

.manager-bottom-buttons-start{
    display: flex;
    gap: 10px;
}
