.login-button-auth{
    display: flex;
    flex-direction: column;
    padding-bottom: 50px;
}

.login-item-auth{
    margin-top: 20px;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    justify-content: center;
    display: flex;
}