.list-container{
    width: 300px;
}

.redactor-container{
    margin-left: 50px;
}

.news-item-status{
    display: flex;
    align-items: center;
}

.news-main-container{
    display: flex;
    flex-direction: row;
    margin-top: 20px;
    margin-left: 50px;
    margin-bottom: 20px;
}

.news-button-bottom{
    display: flex;
    align-items: center;
    justify-content: space-between;
}