.confedential-body-container {
    margin-left: 50px;
    margin-right: 50px;
    margin-top: 30px;
    margin-bottom: 50px;
}

.caption {
    font-size: 50px;
    font-family: 'Roboto', sans-serif;
    margin-bottom: 50px;
    display: flex;
    justify-content: center;
}