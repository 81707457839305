.account-body{
    padding: 50px;
}

.warning-account-message{
    color: rgb(171, 22, 22);
    margin-top: 20px;
    margin-bottom: 12px;
}

.requests-body{
    padding-top: 0px;
    padding-left: 50px;
    padding-right: 50px;
    padding-bottom: 50px;
}

.request-element-body{
    margin-bottom: 0px;
}

.request-element-header{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.request-element-small{
    display: flex;
    justify-content: flex-start;
    gap: 10px;
    margin-bottom: 10px;
    align-items: center;
}

.request-element-status{
    border-radius: 20px;
    border: 1px solid;
    padding: 7px;
}

.request-element-header-text{
    font-size: 20px;
}

.request-message-header{
    display: flex;
    justify-content: flex-start;
    gap: 10px;
    font-size: 10px;
    margin-left: 20px;
    opacity: 0.5;
}

.request-message-text{
    font-size: 12px;
    word-wrap: break-word;
}

.request-message-border{
    border: 0px solid black;
    padding: 10px;
    border-radius: 20px 20px 20px 0px;
    background-color: #e7e0d9;
}